<template>

    <div class="view_container">
        
        <div id="content_main">
            <br>
            <div id="cart_title_table_container">
                <table id="cart_title_table">
                    <tr>
                        <td id="cart_title">{{ languageData.kosarica }}</td>
                        <td><img id="cart_supermarket_basket_icon" :src="getSupermarketBasketImage" /></td>
                    </tr>
                </table>
                <br>
                <table id="cart_items_table" v-if="getCartItems.length > 0">
                    <thead>
                        <tr>
                            <th class="cart_item_th_title">{{ languageData.kosarica_naziv_artikla }}</th>
                            <th class="cart_item_th_amount">{{ languageData.kosarica_kolicina }}</th>
                            <th class="cart_item_th_price">{{ languageData.kosarica_cena }}</th>
                            <th class="cart_item_th_add_remove">{{ languageData.kosarica_upravljanje }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in getCartItems" :key="index">
                        <td class="cart_item_name">{{ itemName(item) }}</td>
                        <td class="cart_item_amount">{{ item.amount }}<span id="x">x</span></td>
                        <td class="cart_item_price">{{ item.price }}<span class="currency">€</span></td>
                        <td class="cart_item_add_remove"><span @click="goToCartItem(item)">{{ languageData.uredi }}&nbsp;<i class="fas fa-edit"></i></span></td>
                        <!--<td><img class="edit_image" :src="getEditImage" alet="Edit" /></td>-->
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div id="content_footer">
            <div class="footer_btn_item">
                <button class="btn_footer btn_footer_blue" @click="goBack()"><i id="footer_btn_position_left" class="fas fa-times"></i>&nbsp;&nbsp;{{ languageData.nakupuj_naprej }}</button>
            </div>
            <div class="footer_btn_item">
                <table id="table_amount" width="100%;" height="92px;">
                    <tbody>
                        <tr id="title">
                            <td>
                                {{ languageData.skupaj_za_placilo }}
                            </td>
                        </tr>
                        <tr id="amount">
                            <td>
                                <!--{{ sum }}<span class="currency">€</span>-->
                                {{ getCartItemsTotal }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="footer_btn_item">
                <button class="btn_footer btn_footer_green" @click="goToPayment()">{{ languageData.na_placilo }} <i id="footer_btn_position_right" class="fas fa-chevron-right"></i></button>
            </div>
        </div>

    </div>

</template>

<script>

import supermarketBasket from '@/assets/icons/supermarket_basket.png'
import editImg from '@/assets/icons/edit.png'
import {formatPrice} from '@/helpers/utilities.js'

export default {

    data: () => ({
        title: "Košarica",
        sum: "0,00",
        cartItems: [
            {
                name: "Vstopnica Vintgar",
                category: 'Odrasli',
                price: 10,
                amount: 2,
                type: 'vstopnica'
            },

            {
                name: "Vstopnica Vintgar",
                category: 'Otroci do 6 let',
                price: 1,
                amount: 1,
                type: 'vstopnica'
            },

            {
                name: "Parkiranje - Osebno vozilo",
                category: 'Odrasli',
                price: 5,
                amount: 1,
                type: 'parking'
            },

            {
                name: '"Vintgar" prevoz nazaj na izhodišče',
                category: 'Odrasli',
                price: 6,
                amount: 3,
                type: 'prevoz'
            }
        ]
    }),

    computed: {
        getSupermarketBasketImage() {
            return supermarketBasket;
        },

        getEditImage() {
            return editImg;
        },

        getCartItemsTotal() {
            //console.log(this.$store.getters.price)
            //var number = 350.0;

            //console.log(new Intl.NumberFormat('de-DE').format(number));
            //return new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 2, minimumFractionDigits: 2 }).format(this.$store.getters.price)
            return formatPrice(this.$store.getters.price)
        },

        getCartItems() {
            return this.$store.getters.cartItemsAggregated
        },

        languageData() {
            return this.$store.getters.languageData
        }
    },

    methods: {
        goBack() {
            return this.$router.push({
                name: 'Store'
            })
        },

        goToPayment() {
            return this.$router.push({
                name: 'Payment'
            })
        },

        goToCartItem(item) {
            return this.$router.push({
                name: 'CartItem',
                params: {
                    id: item.id
                }
            })
        },

        itemName(item) {
            let name = ''
            name = item.name

            if(item.category != '') {
                name += ' - ' + item.category
            }

            return name
        }
    },

    created() {

    },

    mounted() {

    },

    unmounted() {

    }

}

</script>

<style scoped>
    #cart_title_table {
        width:160px;
        /*border:1px solid red;*/
        margin-left: auto;
        margin-right:auto;
        margin-bottom:15px;
    }


    #cart_title {
        width:100%;
        text-align:center;
        color: #007CA4;
        font-size: 18px;
        font-weight: 600;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        text-transform:capitalize;
    }

    #cart_supermarket_basket_icon {
        width: 32px;
    }

    #cart_title_table_container {
        width:95%;
        /*border:3px solid orange;*/
        text-align:center;
        /*margin-top:20px;*/
        margin-left:2.5%;
        margin-right:2.5%;
    }

    #table_amount #title {
        
        text-align:center;
        font-size:18px;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        color: #656565;
        font-weight:600;
    }

    #table_amount #amount {
        text-align:center;
        font-size:38px;
        color: #007CA4;
        font-weight:500;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
    }

    #table_amount {
        background: #ECECEC;
    }




    #cart_items_table {
        width: 100%;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        margin-left:auto;
        margin-right:auto;
    }

    #cart_items_table tbody {
        color: #007CA4;
        font-weight:600;

    }
    
    #cart_items_table th {
       padding:10px; 
       font-size:18px;
    }

    .cart_item_th_add_remove {
        text-align:right;
    }

    #cart_items_table td {
       padding:10px; 
       font-size:18px;
       border-top:1px solid #C8C9C9;
    }

    .cart_item_th_title {
        text-align:left;

    }

    .cart_item_th_title_amount {
        
    }

    .cart_item_th_title_price {

    }

    .cart_item_th_title_add_delete {

    }

    .cart_item_name {
        text-align:left;
    }

    .cart_item_add_remove {
        text-align:right;
        text-transform: capitalize;
    }

    #x {
        margin-left:3px;
    }

    .edit_image {
        width:24px;
    }

    .currency {
        margin-left:3px;
    }

    .cart_item_price {
        color: #000;
    }

    #content_main {
        min-height:76vh;
        /*border:1px solid orange;*/
        margin:5px;
    }
    
</style>